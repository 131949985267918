import React, { useEffect, useState } from "react";
import { File, Folder } from "react-feather";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import DangerButton from "../../components/infrastructure/Buttons/DangerButton";
import _ from "lodash";
import { deleteObject } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import { useDispatch } from "react-redux";
import { checkURL } from "../../utils/Utils";
import { toast } from "react-toastify";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { ClipLoader } from "react-spinners";

function MediaLibraryItem({ asset, baseUrl, del = true }) {
  const folder = location.pathname.replace("/media-library", "")
    ? `${location.pathname.replace("/media-library/", "")}/`
    : "/";
  const decodedFolder = window.decodeURI(folder);
  const [signedUrl, setSignedUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(async () => {
    setLoading(true);
    let data = {
      key: asset.Key,
    };
    const resp = await authAxiosInstance.post(`/media-library/signedUrl`, data);
    if (resp.data) {
      setSignedUrl(resp.data.data);
    }
    setLoading(false);
  }, [asset.Key]);

  return (
    <>
      {loading ? (
        <ClipLoader />
      ) : (
        <div
          onClick={() => {
            navigator.clipboard.writeText(signedUrl);
            toast.info("url copied");
          }}
          className="bg-white shadow-lg rounded-sm border border-slate-200 max-h-[50vh]"
        >
          <div className="flex w-full flex-col items-center gap-2 h-full">
            <div className="p-4 w-full h-full justify-center flex max-h-[30vh]">
              {signedUrl ? (
                <img src={signedUrl} className="w-full object-contain " />
              ) : (
                <File size={100} />
              )}
            </div>
            <div className="bg-slate-300 w-full h-full max-h-[20vh] items-start justify-center flex-col flex">
              <p className="w-3/4 truncate text-black p-4">
                {_.last(asset.Key.split("/"))}
              </p>
              {del ? (
                <div className="flex items-center gap-2 p-2">
                  <DangerButton
                    onClick={() => {
                      dispatch(
                        deleteObject({
                          keys: [asset.Key],
                          currentFolder: decodedFolder,
                        })
                      );
                    }}
                  >
                    Delete
                  </DangerButton>
                  <PrimaryButton
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(asset.Key);
                      toast.info("image key copied");
                    }}
                  >
                    Copy Key
                  </PrimaryButton>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MediaLibraryItem;
