import React, { useEffect, useState } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { array, object, string } from "yup";
import moment from "moment";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import FormikPhoneInputGroup from "../../components/formik/FormikPhoneInputGroup";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import { generateOptions } from "../../utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProducts,
  getProducts,
} from "../../app/reducers/Product/productSlice";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { Plus, X, XSquare } from "react-feather";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import FormikDirectFileUpload from "../../components/formik/FormikDirectFileUpload";
import { createLead, fetchLeads } from "../../app/reducers/Lead/leadSlice";
import FormikTextareaGroup from "../../components/formik/FormikTextareaGroup";
import {
  fetchReferences,
  getReferences,
} from "../../app/reducers/Reference/referenceSlice";
import {
  fetchStatuses,
  getStatuses,
} from "../../app/reducers/Status/statusSlice";
import { ClipLoader } from "react-spinners";
import FormikCreatableSelect from "../../components/formik/FormikCreatableSelect";
import { authAxiosInstance } from "../../utils/axiosConfig";
import FormikTime from "../../components/formik/FormikTime";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { LEAD_MEETING_SCHEDULE } from "../../utils/dropdownOptions";
import FormikLabel from "../../components/formik/FormikLabel";
import { getAuth } from "../../app/reducers/Auth/authSlice";
import {
  fetchEmployees,
  getEmployees,
} from "../../app/reducers/Users/EmployeeSlice";
import { fetchGroups, getGroups } from "../../app/reducers/Group/groupSlice";
import { toast } from "react-toastify";
import {
  fetchDematHoldings,
  getDematHoldings,
} from "../../app/reducers/DematHolding/dematHoldingSlice";
import FormikAsyncSelect from "../../components/formik/FormikAsyncSelect";
import QueryString from "qs";
import GroupCreatePopup from "./GroupCreatePopup";

// const generateOptions = (data)=>{
//   return data?.map((ele)=>({
//     label : ele.name,
//     value : ele._id
//   }))
// };

const AddLead = () => {
  const [isLoading, setIsLoading] = useState({
    ref: false,
    stat: false,
    grp: false,
    // demat: false,
  });
  const { user } = useSelector(getAuth);
  const [groupCreateModalOpen, setGroupCreateModalOpen] = useState(false);
  const { product } = useSelector(getProducts);
  const { reference, loading: refLoading } = useSelector(getReferences);
  const { status, loading: statsLoading } = useSelector(getStatuses);
  const { group, loading: groupLoading } = useSelector(getGroups);
  // const { demat, loading: dematLoading } = useSelector(getDematHoldings);
  const { employees, loading: employeesLoading } = useSelector(getEmployees);
  const dispatch = useDispatch();

  const convertCustomTimeToDate = (selectedDate, customTime) => {
    const milliseconds = parseInt(selectedDate, 10);
    const date = new Date(milliseconds);
    const formattedDateString = date.toString();
    const timeString = customTime.split(" ")[4];

    const [hours, minutes, seconds] = timeString.split(":").map(Number);

    const newDate = new Date(formattedDateString);

    newDate.setHours(hours);
    newDate.setMinutes(minutes);
    newDate.setSeconds(seconds);

    return newDate;
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      emails: [""],
      contactNumbers: ["91"],
      reference: "",
      notes: "",
      products: [],
      isMeetingSchedule: "no",
      meetingDate: null,
      meetingTime: null,
      aadhaarCardFront: "",
      aadhaarCardBack: "",
      panCard: "",
      cheque: "",
      signature: "",
      accountStatement: "",
      bankDetails: [],
      files: [],
      status: "",
      place: "",
      group: "",
      groupName: "",
      // demat: "",
      // boId: "",
      clientCode: "",
      birth: null,
      birthDate: null,
      birthMonth: null,
      anniversary: null,
      anniversaryDate:null,
      anniversaryMonth : null,
      activationDate: null,
      createdBy: user._id,
    },
    validationSchema: object({
      name: string().required(),
      reference: string().required(),
      status: string().required(),
      group: string().required(),
      products: array(),
      contactNumbers: array().min(1, "At least one contact number is required"),
      bankDetails: array().of(
        object({
          bank: string(),
          accountNo: string(),
          IFSC_Code: string().matches(
            /^[A-Z]{4}0[A-Z0-9]{6}$/,
            "Invalid IFSC Code format"
          ),
        })
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        console.log('values', values)
        const existingPhoneResp = await dispatch(
          fetchLeads({
            editStatus: "approved",
            contactNumbers: { $in: values.contactNumbers },
          })
        );

        let text = "";

        if (existingPhoneResp.payload.data.docs.length > 0) {
          const data = existingPhoneResp.payload.data.docs[0];
          text = `${
            data.leadNumber
          } Lead is already present with this ${data?.contactNumbers
            ?.map((el) => el)
            .join(", ")}${
            data?.contactNumbers?.length > 0 ? "," : ""
          } phone number, if you want to continue press\n OK or Cancel`;
        }

        if (text && window.confirm(text) === false) {
          return;
        }
        const birthDay = moment(values?.birth).format('DD');
        const birthMonth = moment(values?.birth).format('MM');
        const anniversaryDay = moment(values?.anniversary).format('DD');
        const anniversaryMonth = moment(values?.anniversary).format('MM');

        if (values?.meetingDate !== null) {
          let newDateStartTime = convertCustomTimeToDate(
            values?.meetingDate,
            values?.meetingTime.toString()
          );
          let newObj = {
            ...values,
            meetingDate: newDateStartTime,
            meetingTime: newDateStartTime,
            birthDate: birthDay,
            birthMonth: birthMonth,
            anniversaryDate:anniversaryDay,
            anniversaryMonth : anniversaryMonth
          };
          console.log('object',newObj)
          dispatch(createLead(newObj));
        } else {
          console.log('null meeting', {...values,birthDate: birthDay,
            birthMonth: birthMonth,
            anniversaryDate:anniversaryDay,
            anniversaryMonth : anniversaryMonth})
          dispatch(createLead({
            ...values,
            birthDate: birthDay,
            birthMonth: birthMonth,
            anniversaryDate:anniversaryDay,
            anniversaryMonth : anniversaryMonth
          }));
        }
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error("failed to add lead");
      }
    },
  });

  useEffect(() => {
    dispatch(fetchProducts({ populate: true }));
    dispatch(fetchReferences({ populate: true }));
    dispatch(fetchStatuses({ populate: true }));
    dispatch(fetchEmployees({ populate: true }));
    dispatch(fetchGroups({ populate: true }));
    // dispatch(fetchDematHoldings({ populate: true }));
  }, []);

  useEffect(() => {
    if (formik.values.isMeetingSchedule === "yes") {
      formik.setFieldValue("meetingDate", moment().startOf("day").valueOf());
      formik.setFieldValue("meetingTime", moment().toDate());
    } else {
      formik.setFieldValue("meetingDate", null);
      formik.setFieldValue("meetingTime", null);
    }
  }, [formik.values.isMeetingSchedule]);

  const handleRefCreate = async (inputValue, route, formikName) => {
    setIsLoading((state) => ({ ...state, ref: true }));
    let data = { name: inputValue };
    const resp = await authAxiosInstance.post(`/${route}`, data);

    if (resp.data) {
      await dispatch(fetchReferences({ populate: true })).then((res) => {
        formik.setFieldValue(formikName, resp.data?.data?._id.toString());
      });
      setIsLoading((state) => ({ ...state, ref: false }));
    }
  };

  const handleStatCreate = async (inputValue, route, formikName) => {
    setIsLoading((state) => ({ ...state, stat: true }));
    let data = { name: inputValue };
    const resp = await authAxiosInstance.post(`/${route}`, data);

    if (resp.data) {
      await dispatch(fetchStatuses({ populate: true })).then((res) => {
        formik.setFieldValue(formikName, resp.data?.data?._id.toString());
      });
      setIsLoading((state) => ({ ...state, stat: false }));
    }
  };
  const handelGroupCreate = async (inputValue, route, formikName) => {
    setIsLoading((state) => ({ ...state, grp: true }));
    let data = { name: inputValue };
    const resp = await authAxiosInstance.post(`/${route}`, data);

    if (resp.data) {
      await dispatch(fetchGroups({ populate: true })).then((res) => {
        formik.setFieldValue(formikName, resp.data?.data?._id.toString());
        formik.setFieldValue("groupName", resp.data?.data?.name?.toString());
      });
      setIsLoading((state) => ({ ...state, grp: false }));
    }
  };
  // const handelDematHoldingCreate = async (inputValue, route, formikName) => {
  //   setIsLoading((state) => ({ ...state, demat: true }));
  //   let data = { name: inputValue };
  //   const resp = await authAxiosInstance.post(`/${route}`, data);

  //   if (resp.data) {
  //     await dispatch(fetchDematHoldings({ populate: true })).then((res) => {
  //       formik.setFieldValue(formikName, resp.data?.data?._id.toString());
  //     });
  //     setIsLoading((state) => ({ ...state, demat: false }));
  //   }
  // };

  return (
    <PageWithCard heading="Add Prospect">
      <GroupCreatePopup
        setGroupCreateModalOpen={setGroupCreateModalOpen}
        groupCreateModalOpen={groupCreateModalOpen}
      />
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
        <div className="flex flex-col md:flex-row items-center gap-4">
          <div className="w-full">
            <FormikInputGroup
              formik={formik}
              label="Name"
              name="name"
              required
            />
          </div>
          <div className="w-full">
            <FormikInputGroup formik={formik} label="Place" name="place" />
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center gap-4">
          <div className="w-full">
            <FormikProvider value={formik} name="contactNumbers">
              <FieldArray
                name="contactNumbers"
                render={(arrayHelpers) => {
                  return (
                    <div className="flex flex-col my-2 gap-2 rounded-lg">
                      {formik?.values?.contactNumbers?.map((ele, index) => (
                        <div className="mb-2" key={index}>
                          <div className="relative">
                            {index !== 0 && (
                              <X
                                className=" right-0 text-red-500"
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            )}

                            <div className="flex flex-col gap-2">
                              <div className="w-full flex flex-row justify-between items-center">
                                <FormikLabel label="Phone" />
                                <div className="w-[80%]">
                                  <FormikPhoneInputGroup
                                    formik={formik}
                                    name={`contactNumbers.${index}`}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div>
                        <SecondaryButton
                          type="button"
                          onClick={() => {
                            arrayHelpers.push("91");
                          }}
                        >
                          Add more
                        </SecondaryButton>
                      </div>
                    </div>
                  );
                }}
              />
            </FormikProvider>
          </div>
          <div className="w-full">
            <FormikProvider value={formik} name="emails">
              <FieldArray
                name="emails"
                render={(arrayHelpers) => {
                  return (
                    <div className="flex flex-col my-2 gap-2 rounded-lg">
                      {formik?.values?.emails?.map((ele, index) => (
                        <div className="mb-2" key={index}>
                          <div className="relative">
                            {index !== 0 && (
                              <X
                                className=" right-0 text-red-500"
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            )}

                            <div className="flex flex-col gap-2">
                              <div className="w-full flex flex-row justify-between items-center">
                                <FormikLabel label="Email" />
                                <div className="w-[80%]">
                                  <FormikInputGroup
                                    formik={formik}
                                    name={`emails.${index}`}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div>
                        <SecondaryButton
                          type="button"
                          onClick={() => {
                            arrayHelpers.push("");
                          }}
                        >
                          Add more
                        </SecondaryButton>
                      </div>
                    </div>
                  );
                }}
              />
            </FormikProvider>
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center gap-4">
          <div className="w-full">
            {refLoading ? (
              <ClipLoader />
            ) : (
              <FormikCreatableSelect
                formik={formik}
                label="Source"
                name="reference"
                required
                isLoading={isLoading.ref}
                isDisabled={isLoading.ref}
                options={generateOptions({
                  array: reference?.docs ? reference?.docs : [],
                  labelField: "name",
                  valueField: "_id",
                })}
                onCreateOption={(value) =>
                  handleRefCreate(value, "reference", "reference")
                }
              />
            )}
          </div>
          <div className="w-full">
            {statsLoading ? (
              <ClipLoader />
            ) : (
              <FormikCreatableSelect
                formik={formik}
                label="Status"
                name="status"
                required
                isLoading={isLoading.stat}
                isDisabled={isLoading.stat}
                options={generateOptions({
                  array: status?.docs ? status?.docs : [],
                  labelField: "name",
                  valueField: "_id",
                })}
                onCreateOption={(value) =>
                  handleStatCreate(value, "status", "status")
                }
              />
            )}
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center gap-4">
          <div className="w-full">
            <FormikMultiSelect
              formik={formik}
              label="Products"
              name="products"
              options={generateOptions({
                array: product?.docs ? product?.docs : [],
                labelField: "name",
                valueField: "_id",
              })}
            />
          </div>
          <div className="w-full">
            {groupLoading ? (
              <ClipLoader />
            ) : (
              <FormikAsyncSelect
                formik={formik}
                label={
                  <div className="flex gap-2">
                    <label>
                      Group <span className="text-rose-500">*</span>
                    </label>
                    <Plus
                      onClick={(e) => {
                        e.stopPropagation();
                        setGroupCreateModalOpen(true);
                      }}
                    />
                  </div>
                }
                name="group"
                required
                getOptions={async (value) => {
                  const data = {
                    search: value,
                  };
                  const string = QueryString.stringify(data);
                  const groupResp = await authAxiosInstance.get(
                    `group?${string}`
                  );
                  const groups = groupResp?.data?.data?.docs?.length
                    ? groupResp.data.data.docs
                    : [];
                  return groups.map((ele) => ({
                    label: ele.name,
                    value: ele._id,
                  }));
                }}
                onChange={(selectedOption) => {
                  formik.setFieldValue("groupName", selectedOption.label);
                  formik.setFieldValue("group", selectedOption.value);
                }}
              />
            )}
          </div>
        </div>

        {/*

        <div className="w-full md:w-1/2">
          {dematLoading ? (
            <ClipLoader />
          ) : (
            <FormikCreatableSelect
              formik={formik}
              label="Demat Holding"
              name="demat"
              required
              isLoading={isLoading.demat}
              isDisabled={isLoading.demat}
              options={generateOptions({
                array: demat?.docs ? demat?.docs : [],
                labelField: "name",
                valueField: "_id",
              })}
              onCreateOption={(value) =>
                handelDematHoldingCreate(value, "demat", "demat")
              }
            />
          )}
        </div>

        <div className="flex flex-col md:flex-row items-center gap-4">
          <div className="w-full">
            <FormikInputGroup formik={formik} label="BO ID" name="boId" />
          </div>
          <div className="w-full">
            <FormikInputGroup
              formik={formik}
              label="Client Code"
              name="clientCode"
            />
          </div>
        </div>
        */}

        <div className="flex flex-col md:flex-row items-center gap-4">
          <div className="w-full">
            <FormikSelectGroup
              formik={formik}
              label="Meeting Schedule Status"
              name="isMeetingSchedule"
              options={LEAD_MEETING_SCHEDULE}
            />
          </div>
          <div className="w-full">
            {formik.values.isMeetingSchedule === "yes" && (
              <div className="w-full flex flex-row items-center gap-4">
                <div className="w-full">
                  <FormikInputDateGroup
                    formik={formik}
                    label="Meeting Date"
                    name="meetingDate"
                  />
                </div>
                <div className="w-full">
                  <FormikTime
                    formik={formik}
                    label="Meeting Time"
                    name="meetingTime"
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        {formik.values.groupName && formik.values.name && (
          <>
            <div className="w-full grid grid-cols-3 gap-4">
              <FormikDirectFileUpload
                name="aadhaarCardFront"
                label="Aadhaar Card Front"
                formik={formik}
                location={`${formik.values.groupName}/${formik.values.name}/`}
                randomize
              />
              <FormikDirectFileUpload
                name="aadhaarCardBack"
                label="Aadhaar Card Back"
                formik={formik}
                location={`${formik.values.groupName}/${formik.values.name}/`}
                randomize
              />
              <FormikDirectFileUpload
                name="panCard"
                label="Pan Card"
                formik={formik}
                location={`${formik.values.groupName}/${formik.values.name}/`}
                randomize
              />
              <FormikDirectFileUpload
                name="signature"
                label="Signature"
                formik={formik}
                randomize
                location={`${formik.values.groupName}/${formik.values.name}/`}
              />
              <FormikDirectFileUpload
                name="accountStatement"
                label="Account Statement"
                formik={formik}
                location={`${formik.values.groupName}/${formik.values.name}/`}
                randomize
              />
              <FormikDirectFileUpload
                name="cheque"
                label="Cheque"
                formik={formik}
                location={`${formik.values.groupName}/${formik.values.name}/`}
                randomize
              />
              <FormikDirectFileUpload
                name="nomineeAadharCardFront"
                label="Nominee Aadhar Card Front"
                formik={formik}
                location={`${formik.values.groupName}/${formik.values.name}/`}
                randomize
              />
              <FormikDirectFileUpload
                name="nomineeAadharCardBack"
                label="Nominee Aadhaar Card Back"
                formik={formik}
                location={`${formik.values.groupName}/${formik.values.name}/`}
                randomize
              />
              <FormikDirectFileUpload
                name="nomineePanCard"
                label="Nominee Pan Card"
                formik={formik}
                location={`${formik.values.groupName}/${formik.values.name}/`}
                randomize
              />
            </div>
          </>
        )}
        {formik.values.groupName && formik.values.name && (
          <FormikProvider value={formik}>
            <FieldArray
              name="files"
              render={(arrayHelpers) => {
                return (
                  <>
                    <div className="mb-2 ">
                      <SecondaryButton
                        onClick={(e) => {
                          e.stopPropagation();
                          arrayHelpers.push({
                            fileName: "",
                            fileDocs: "",
                          });
                        }}
                      >
                        Add Additional Files
                      </SecondaryButton>
                    </div>
                    <table className="table-auto w-full">
                      <tbody className="text-sm divide-y divide-slate-200">
                        {formik.values.files.map((ele, i) => (
                          <tr
                            key={i}
                            className="flex flex-row items-start pl-6"
                          >
                            <td className="px-2 pb-7 whitespace-nowrap first:pl-5 last:pr-5 relative w-[30%] ">
                              <div className="">
                                <XSquare
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    arrayHelpers.remove(i);
                                  }}
                                  className="absolute top-[40%] left-[-15%] md:left-[-8%] cursor-pointer text-red-600 hover:text-red-500"
                                />
                                <FormikInputGroup
                                  formik={formik}
                                  name={`files.${i}.fileName`}
                                  label="File Name"
                                />
                              </div>
                            </td>
                            <td className="w-[50%] flex flex-wrap ">
                              <div className="text-ellipsis overflow-hidden whitespace-nowrap pl-2">
                                <FormikDirectFileUpload
                                  name={`files.${i}.fileDocs`}
                                  formik={formik}
                                  label="Choose File"
                                  randomize={true}
                                  location={`Group/documents/`}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                );
              }}
            />
          </FormikProvider>
        )}

        {/* <div className="w-full md:w-1/2 mt-4">
          <FormikProvider value={formik}>
            <FieldArray
              name="bankDetails"
              render={(arrayHelpers) => {
                return (
                  <>
                    <div className="">
                      <SecondaryButton
                        onClick={(e) => {
                          e.stopPropagation();
                          arrayHelpers.push({
                            accountNo: "",
                            IFSC_Code: "",
                            bank: "",
                          });
                        }}
                      >
                        Add Bank Details
                      </SecondaryButton>
                    </div>
                    <div className="flex flex-col my-2 gap-2 rounded-lg">
                      {formik?.values?.bankDetails?.map((ele, index) => (
                        <div className="mb-2" key={index}>
                          <div className="relative">
                            <XSquare
                              className=" right-0 text-red-600 cursor-pointer hover:text-red-500"
                              onClick={() => arrayHelpers.remove(index)}
                            />

                            <div className="flex flex-col gap-2">
                              <div className="w-full flex flex-row justify-between items-center">
                                <FormikLabel label="Bank" />
                                <div className="w-[70%]">
                                  <FormikInputGroup
                                    formik={formik}
                                    name={`bankDetails.${index}.bank`}
                                  />
                                </div>
                              </div>
                              <div className="w-full flex flex-row justify-between items-center">
                                <FormikLabel label="Account No." />
                                <div className="w-[70%]">
                                  <FormikInputGroup
                                    formik={formik}
                                    name={`bankDetails.${index}.accountNo`}
                                    randomize
                                  />
                                </div>
                              </div>
                              <div className="w-full flex flex-row justify-between items-center">
                                <FormikLabel label="IFSC Code" />
                                <div className="w-[70%]">
                                  <FormikInputGroup
                                    formik={formik}
                                    name={`bankDetails.${index}.IFSC_Code`}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                );
              }}
            />
          </FormikProvider>
        </div> */}

        <FormikTextareaGroup formik={formik} label="Notes" name="notes" />
        <div className="w-full flex flex-row items-center gap-4">
          <div className="w-full">
            <FormikInputGroup
              formik={formik}
              label="Client Code"
              name="clientCode"
            />
          </div>
          <div className="w-full">
            <FormikInputDateGroup
              formik={formik}
              name="activationDate"
              label="Activation Date"
            />
          </div>
        </div>
        <div className="w-full flex flex-row items-center gap-4">
          <div className="w-full">
            <FormikInputDateGroup
              formik={formik}
              name="birth"
              label="Birthday Date"
              showYearDropdown
              showMonthDropdown
            />
          </div>
          <div className="w-full">
            <FormikInputDateGroup
              formik={formik}
              name="anniversary"
              label="Anniversary Date"
              showYearDropdown
              showMonthDropdown
            />
          </div>
        </div>

        <FormikSelectGroup
          formik={formik}
          label="Created By"
          options={generateOptions({
            array: employees?.docs?.map((ele) => ({
              name: `${ele.firstName} ${ele.lastName}`,
              value: ele._id,
            })),
            labelField: "name",
            valueField: "value",
          })}
          name="createdBy"
        />
        <div>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </div>
      </form>
    </PageWithCard>
  );
};

export default AddLead;
