import React, { useEffect, useMemo, useState } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { useFormik } from "formik";
import {
  createDematHolding,
  deleteDematHolding,
  fetchDematHoldings,
  getDematHoldings,
} from "../../app/reducers/DematHolding/dematHoldingSlice";
import { useDispatch, useSelector } from "react-redux";
import EditGroupModal from "./EditGroupModal";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import { object, string } from "yup";
import { Edit, Trash } from "react-feather";

const DematHolding = () => {
  const [requestData, setRequestData] = useState({});
  const [groupEditModal, setGroupEditModal] = useState(false);

  const { demat } = useSelector(getDematHoldings);
  console.log('demat', demat)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDematHoldings({ sort: { name: 1 } }));
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: object({
      name: string().label("Demat Holding").required(),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log("values", values);
      dispatch(createDematHolding(values)).then((res) => {
        if (res.payload.success) {
          dispatch(fetchDematHoldings({ sort: { name: 1 } }));
        }
      });
      resetForm();
    },
  });

  const dematCols = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: " ",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row gap-4">
            <Edit
              color="blue"
              className="cursor-pointer"
              onClick={async (e) => {
                e.stopPropagation();
                setRequestData(row?.original);
                setGroupEditModal(true);
              }}
            />
            <Trash
              color="red"
              className="cursor-pointer"
              onClick={async (e) => {
                e.stopPropagation();
                await dispatch(
                  deleteDematHolding({
                    id: row?.original?._id,
                  })
                );
                await dispatch(fetchDematHoldings({ sort: { name: 1 } }));
              }}
            />
          </div>
        );
      },
    },
  ];

  const dematMemo = useMemo(() => {
    if (demat && demat?.docs) {
      return demat?.docs;
    } else {
      return [];
    }
  }, [demat]);
  return (
    <PageWithCard heading="Demat Holding">
      <EditGroupModal
        requestData={requestData}
        openEditModal={groupEditModal}
        setOpenEditModal={setGroupEditModal}
      />
      <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
        <FormikInputGroup formik={formik} label="Name" name="name" required />
        <div>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </div>
      </form>
      <div className="mt-4">
        <TableWithHeadingAndSearch
          heading="All Demat"
          columns={dematCols}
          data={dematMemo}
        />
      </div>
    </PageWithCard>
  );
};

export default DematHolding;
