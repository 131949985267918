import React from "react";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";
import { useFormik } from "formik";
import { object, string } from "yup";
import { toast } from "react-toastify";
import { authAxiosInstance } from "../../utils/axiosConfig";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";

const GroupCreatePopup = ({
  groupCreateModalOpen,
  setGroupCreateModalOpen,
}) => {
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: object({
      name: string().required(),
    }),
    onSubmit: async (values) => {
      try {
        const existingGroupResp = await authAxiosInstance.get(
          `/group?name=${values.name}`
        );
        if (existingGroupResp?.data?.data?.docs?.length > 0) {
          alert("Group already exists");
          return;
        }
        const createResp = await authAxiosInstance.post("/group", values);

        if (createResp.status === 200) {
          toast.success("added Group");
        }
      } catch (error) {
        console.log(error);
        toast.error("failed to create Group");
      }
    },
  });
  return (
    <ModalBasic
      modalOpen={groupCreateModalOpen}
      setModalOpen={setGroupCreateModalOpen}
    >
      <form className="p-4 flex flex-col gap-2" onSubmit={formik.handleSubmit}>
        <FormikInputGroup label="Name" name={"name"} required formik={formik} />
        <div>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </div>
      </form>
    </ModalBasic>
  );
};

export default GroupCreatePopup;
