import React from "react";
import ModalBasic from "../../components/infrastructure/Modals/ModalBasic";
import { useSelector } from "react-redux";
import { getMediaLibrary } from "../../app/reducers/MediaLibrary/mediaLibrarySlice";
import { Download } from "react-feather";
import DocumentDownload from "./DocumentDownload";
import { DoughnutController } from "chart.js";

const DocumentModal = ({
  documentModalOpen,
  setDocumentModalOpen,
  requestData,
}) => {
return (
    <ModalBasic
      modalOpen={documentModalOpen}
      setModalOpen={setDocumentModalOpen}
    >
      <div className="p-2">
        <table className="table-auto w-full border border-black">
          <thead className="">
            <tr>
              <th className="px-2 py-3 border">Document Name</th>
              <th className="px-2 py-3 border">Download</th>
            </tr>
          </thead>
          <tbody>
            <tr className="p-2 border">
              <td className="text-left px-2 py-3 border">
                Aadhaar Card Front Side
              </td>
              <td className="text-center px-2 py-3 border ">
                {
                  <button type="button">
                    <DocumentDownload
                      mediaKey={requestData?.aadhaarCardFront}
                    />
                  </button>
                }
              </td>
            </tr>
            <tr className="p-2 border">
              <td className="text-left px-2 py-3 border">
                Aadhaar Card Back Side
              </td>
              <td className="text-center px-2 py-3 border ">
                {
                  <button type="button">
                    <DocumentDownload mediaKey={requestData?.aadhaarCardBack} />
                  </button>
                }
              </td>
            </tr>
            <tr className="p-2 border">
              <td className="text-left px-2 py-3 border">Pan Card</td>
              <td className="text-center px-2 py-3 border ">
                {
                  <button type="button">
                    <DocumentDownload
                      mediaKey={
                        requestData?.panCard ? `${requestData?.panCard}` : null
                      }
                    />
                  </button>
                }
              </td>
            </tr>

            <tr className="p-2 border">
              <td className="text-left px-2 py-3 border">Signature</td>
              <td className="text-center px-2 py-3 border ">
                {
                  <button type="button">
                    <DocumentDownload
                      mediaKey={
                        requestData?.signature
                          ? `${requestData?.signature}`
                          : null
                      }
                    />
                  </button>
                }
              </td>
            </tr>

            <tr className="p-2 border">
              <td className="text-left px-2 py-3 border">Cheque</td>
              <td className="text-center px-2 py-3 border ">
                {
                  <button type="button">
                    <DocumentDownload
                      mediaKey={
                        requestData?.cheque ? `${requestData?.cheque}` : null
                      }
                    />
                  </button>
                }
              </td>
            </tr>

            <tr className="p-2 border">
              <td className="text-left px-2 py-3 border">Account Statement</td>
              <td className="text-center px-2 py-3 border ">
                {
                  <button type="button">
                    <DocumentDownload
                      mediaKey={
                        requestData?.accountStatement
                          ? `${requestData?.accountStatement}`
                          : null
                      }
                    />
                  </button>
                }
              </td>
            </tr>

            <tr className="p-2 border">
              <td className="text-left px-2 py-3 border">
                Nominee Aadhar Card Front
              </td>
              <td className="text-center px-2 py-3 border ">
                {
                  <button type="button">
                    <DocumentDownload
                      mediaKey={
                        requestData?.nomineeAadharCardFront
                          ? `${requestData?.nomineeAadharCardFront}`
                          : null
                      }
                    />
                  </button>
                }
              </td>
            </tr>

            <tr className="p-2 border">
              <td className="text-left px-2 py-3 border">
                Nominee Aadhar Card Back
              </td>
              <td className="text-center px-2 py-3 border ">
                {
                  <button type="button">
                    <DocumentDownload
                      mediaKey={
                        requestData?.nomineeAadharCardBack
                          ? `${requestData?.nomineeAadharCardBack}`
                          : null
                      }
                    />
                  </button>
                }
              </td>
            </tr>

            <tr className="p-2 border">
              <td className="text-left px-2 py-3 border">
                Nominee Aadhar Pan Card
              </td>
              <td className="text-center px-2 py-3 border ">
                {
                  <button type="button">
                    <DocumentDownload
                      mediaKey={
                        requestData?.nomineePanCard
                          ? `${requestData?.nomineePanCard}`
                          : null
                      }
                    />
                  </button>
                }
              </td>
            </tr>

            <tr className="p-2 border">
              <td className="text-left px-2 py-3 border font-bold" colSpan="2">
                Addition Files
              </td>
            </tr>

            {requestData?.files?.map((ele, index) => (
              <tr key={index} className="p-2 border">
                <td className="text-left px-2 py-3 border">
                  {ele.fileName ? ele.fileName : "Document"}
                </td>
                <td className="text-center px-2 py-3 border ">
                  {
                    <button type="button">
                      {/* <a
                        href={`${baseUrl}${ele.fileDocs}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        <Download size={18} color="blue" />
                      </a> */}
                      <DocumentDownload mediaKey={ele.fileDocs} />
                    </button>
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* <div className="mt-2 ">
          <form
            onSubmit={(e) => {
              e.stopPropagation();
              formik.handleSubmit(e);
            }}
          >
            <FormikProvider value={formik}>
              <FieldArray
                name="documents"
                render={(arrayHelpers) => {
                  return (
                    <>
                      <div className="mb-2 ">
                        <SecondaryButton
                          onClick={(e) => {
                            e.stopPropagation();
                            arrayHelpers.push({
                              fileName: "",
                              fileDocs: "",
                            });
                          }}
                        >
                          Add Additional Files
                        </SecondaryButton>
                      </div>
                      <table className="table-auto w-full">
                        <tbody className="text-sm divide-y divide-slate-200">
                          {formik.values.files.map((ele, i) => (
                            <tr
                              key={i}
                              className="flex flex-row items-start pl-6"
                            >
                              <td className="px-2 pb-7 whitespace-nowrap first:pl-5 last:pr-5 relative w-[30%] ">
                                <div className="">
                                  <XSquare
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      arrayHelpers.remove(i);
                                    }}
                                    className="absolute top-[40%] left-[-15%] md:left-[-8%] cursor-pointer text-red-600 hover:text-red-500"
                                  />
                                  <FormikInputGroup
                                    formik={formik}
                                    name={`files.${i}.fileName`}
                                    label="File Name"
                                  />
                                </div>
                              </td>
                              <td className="w-[50%] flex flex-wrap ">
                                <div className="text-ellipsis overflow-hidden whitespace-nowrap pl-2">
                                  <FormikDirectFileUpload
                                    name={`files.${i}.fileDocs`}
                                    formik={formik}
                                    label="Choose File"
                                    randomize={true}
                                    location={`Group/documents/`}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  );
                }}
              />
            </FormikProvider>
            {formik?.values?.documents?.length > 0 && (
              <div>
                <PrimaryButton disabled={formik.isSubmitting} type="submit">
                  Submit
                </PrimaryButton>
              </div>
            )}
          </form>
        </div> */}
      </div>
    </ModalBasic>
  );
};

export default DocumentModal;
