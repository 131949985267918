import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCollections,
  getGeneralBulkUpload,
} from "../../app/reducers/GeneralBulkUpload/generalBulkUploadSlice";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import FormikFileInput from "../../components/formik/FormikFileInput";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { exportCsv, parseCsv } from "../../utils/Utils";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import { authAxiosInstance } from "../../utils/axiosConfig";
import QueryString from "qs";
import { findRelational } from "../../utils/findRelational";
import { toast } from "react-toastify";

const STEP = 1;
const action = "Select Collection";
const Step = ({ formik, currentStep, setCurrentStep }) => {
  const dispatch = useDispatch();
  const { collections } = useSelector(getGeneralBulkUpload);
  useEffect(() => {
    dispatch(fetchCollections());
  }, []);
  const handleFileSubmit = async () => {
    const file = formik.values.csv;
    // console.log("file", file);
    const parseCsvResp = await parseCsv(file);
    const collectionString = QueryString.stringify({
      collection: formik.values.collection,
    });
    const analysisResp = await authAxiosInstance.get(
      `/generalBulkUpload/analyze?${collectionString}`
    );
    const analysisObj = analysisResp.data.data;
    if (!analysisObj) {
      return toast.error("No Upload config found please contact developer");
    }

    formik.setFieldValue("analyzedObj", analysisObj.config);
    formik.setFieldValue("createUrl", analysisObj.createUrl);

    const relationsFieldsData = findRelational(analysisObj.config);
    for (let i = 0; i < relationsFieldsData.length; i++) {
      const field = relationsFieldsData[i];
      const modelString = QueryString.stringify({
        model: field.ref,
      });
      const options = await authAxiosInstance.get(
        `/generalBulkUpload/getRelationalModel?${modelString}`
      );
      console.log(options.data);
      relationsFieldsData[i].fieldOptions = options?.data?.data?.length
        ? options.data.data
        : [];
    }
    formik.setFieldValue("relationalFields", relationsFieldsData);
    formik.setFieldValue("parsedCsvArr", parseCsvResp.data?.data?.docs);
    console.log("parseCsvResp", parseCsvResp.data?.data?.docs);
    setCurrentStep((prev) => prev + 1);
  };

  return (
    <>
      {currentStep + 1 == STEP && (
        <>
          <FormikInputGroup
            formik={formik}
            name={"batchNumber"}
            label="Batch No."
            readOnly
          />
          <FormikSelectGroup
            formik={formik}
            label="Select collection"
            name={"collection"}
            required={true}
            options={collections.map((e) => ({
              label: e,
              value: e,
            }))}
            onChange={async (selectedOption) => {
              formik.setFieldValue("collection", selectedOption.value);
              formik.setFieldValue("fileData", []);
              const collectionString = QueryString.stringify({
                collection: selectedOption.value,
              });
              const analysisResp = await authAxiosInstance.get(
                `/generalBulkUpload/analyze?${collectionString}`
              );
              const analysisObj = analysisResp.data.data;
              console.log('analysisObj', analysisObj)
              if (!analysisObj) {
                return toast.error(
                  "No Upload config found please contact developer"
                );
              }
              if (analysisObj?.fileData) {
                formik.setFieldValue("fileData", analysisObj.fileData);
              } else {
                toast.info("No sample file found on system");
              }
            }}
            //   readOnly={true}
          />

          {formik?.values?.fileData?.length > 0 && (
            <div className="my-4">
              <PrimaryButton
                type={"button"}
                onClick={() => {
                  exportCsv(formik.values.fileData);
                }}
              >
                Download Sample File
              </PrimaryButton>
            </div>
          )}

          <FormikFileInput
            formik={formik}
            label="Upload CSV File"
            name={"csv"}
            required={true}
          />
          <div className="w-full flex justify-end">
            <PrimaryButton
              className={"my-4 "}
              type={"button"}
              onClick={handleFileSubmit}
            >
              Next
            </PrimaryButton>
          </div>
          {/* <div className="my-3">
            <PrimaryButton onClick={handleFileSubmit} type={"button"}>
              Analyze
            </PrimaryButton>
          </div> */}
        </>
      )}
    </>
  );
};

export default {
  Component: Step,
  action: action,
};
