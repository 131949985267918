import { createSlice } from "@reduxjs/toolkit"
import { generateStateSingle } from "../../generators/generateState"
import { createData, deleteData, editData, fetchData, fetchEditData } from "../../generators/generateThunks";

const SLICE_NAME = 'demat';

export const fetchDematHoldings = fetchData.generateThunk(SLICE_NAME,SLICE_NAME);
export const fetchDematHolding = fetchEditData.generateThunk(SLICE_NAME,SLICE_NAME);
export const createDematHolding = createData.generateThunk(SLICE_NAME,SLICE_NAME);
export const editDematHolding = editData.generateThunk(SLICE_NAME,SLICE_NAME);
export const deleteDematHolding = deleteData.generateThunk(SLICE_NAME,SLICE_NAME);

const dematHoldingSlice = createSlice({
    name : SLICE_NAME,
    initialState : generateStateSingle(SLICE_NAME),
    extraReducers :{
        ...fetchData.generateExtraReducers(fetchDematHoldings ,SLICE_NAME),
        ...fetchEditData.generateExtraReducers(fetchDematHolding, SLICE_NAME),
        ...createData.generateExtraReducers(createDematHolding, SLICE_NAME),
        ...editData.generateExtraReducers(editDematHolding, SLICE_NAME),
        ...deleteData.generateExtraReducers(deleteDematHolding, SLICE_NAME),
    }
})

export const getDematHoldings = (state)=> state.demat;

export default dematHoldingSlice.reducer;