import React, { useEffect, useState } from "react";
import { Download } from "react-feather";
import { ClipLoader } from "react-spinners";
import { authAxiosInstance } from "../../utils/axiosConfig";

const DocumentDownload = ({ mediaKey }) => {
  const [signedUrl, setSignedUrl] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    if (mediaKey) {
      setLoading(true);
      let data = {
        key: mediaKey,
        download: true,
      };
      const resp = await authAxiosInstance.post(
        `/media-library/signedUrl`,
        data
      );
      if (resp.data) {
        setSignedUrl(resp.data.data);
      }
      setLoading(false);
    }
  }, [mediaKey]);

  return (
    <>
      {mediaKey ? (
        <>
          {loading ? (
            <ClipLoader />
          ) : (
            <a href={signedUrl} target="_blank" download={true}>
              <Download size={18} color="blue" />
            </a>
          )}
        </>
      ) : (
        "No Document"
      )}
    </>
  );
};

export default DocumentDownload;
