import React, { useEffect, useMemo, useState } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { useFormik } from "formik";
import { object, string } from "yup";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import {
  createGroup,
  deleteGroup,
  fetchGroups,
  getGroups,
} from "../../app/reducers/Group/groupSlice";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import { Edit, Trash } from "react-feather";
import EditGroupModal from "./EditGroupModal";
import { original } from "@reduxjs/toolkit";
import PaginationClassic from "../../components/infrastructure/pagination/PaginationClassic";

const AddGroup = () => {
  const [requestData, setRequestData] = useState({});
  const [groupEditModal, setGroupEditModal] = useState(false);
  const { group } = useSelector(getGroups);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGroups({ sort: { name: 1 }, page }));
  }, [page]);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: object({
      name: string().label("Group Name").required(),
    }),
    onSubmit: (values, { resetForm }) => {
      console.log("values", values);
      dispatch(createGroup(values)).then((res) => {
        if (res.payload.success) {
          dispatch(fetchGroups({ sort: { name: 1 } }));
        }
      });
      resetForm();
    },
  });

  const groupCols = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: " ",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row gap-4">
            <Edit
              color="blue"
              className="cursor-pointer"
              onClick={async (e) => {
                e.stopPropagation();
                setRequestData(row?.original);
                setGroupEditModal(true);
              }}
            />
            <Trash
              color="red"
              className="cursor-pointer"
              onClick={async (e) => {
                e.stopPropagation();
                await dispatch(
                  deleteGroup({
                    id: row?.original?._id,
                  })
                );
                await dispatch(fetchGroups({ sort: { name: 1 } }));
              }}
            />
          </div>
        );
      },
    },
  ];

  const groupMemo = useMemo(() => {
    if (group && group?.docs) {
      return group?.docs;
    } else {
      return [];
    }
  }, [group, page]);

  return (
    <PageWithCard heading="Group">
      <EditGroupModal
        requestData={requestData}
        openEditModal={groupEditModal}
        setOpenEditModal={setGroupEditModal}
      />
      <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
        <FormikInputGroup formik={formik} label="Name" name="name" required />
        <div>
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </div>
      </form>
      <div className="mt-4">
        <TableWithHeadingAndSearch
          heading="All Groups"
          columns={groupCols}
          data={groupMemo}
        />
        <PaginationClassic paginationDetails={group} setPage={setPage} />
      </div>
    </PageWithCard>
  );
};

export default AddGroup;
